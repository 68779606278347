import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { DefaultErrorBoundary } from "src/components/DefaultErrorBoundary/DefaultErrorBoundary";
import { DisplayAd } from "src/components/DisplayAd/DisplayAd";
import styled from "styled-components";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { border_radius } from "src/design-system/tokens/border";
import { CircularTimer } from "src/svg/CirularTimer";
import { mediumBreakpoint, useScreenSize } from "src/utils/hooks/useScreenSize";
import type { getTargeting } from "src/components/DisplayAd/targeting";
import { Typography } from "src/design-system/components/Typography/Typography";
import { color as themeColor } from "../../../../theme";
import messages from "./AdPageWithTimer.messages";

export const TIMER_COUNT = 7;

export function AdPageWithTimer() {
  const intl = useIntl();
  const [externalLink, setExternalLInk] = useState("");
  const [targetingData, setTargetingData] = useState<
    ReturnType<typeof getTargeting> | undefined
  >(undefined);
  const isMobile = useScreenSize() === "small";

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const linkParam = urlParams.get("link");

    if (linkParam) {
      setExternalLInk(decodeURIComponent(linkParam));
    }
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem("adPageData");
    if (storedData) {
      setTargetingData(JSON.parse(storedData));
    }
  }, []);

  const [timeLeft, setTimeLeft] = useState(TIMER_COUNT);
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
    if (timeLeft === 0) {
      clearInterval(timer);
      localStorage.removeItem("adPageData");
      window.location.href = externalLink ?? "";
    }
    return () => clearInterval(timer);
  }, [externalLink, timeLeft]);

  return (
    <DefaultErrorBoundary>
      <Container>
        <AdWithTimer>
          <ContentWrapper>
            <CircularTimer timeLeft={timeLeft} />
            <Title variant="heading-sm">
              {intl.formatMessage(messages.heading)}
            </Title>
            <Typography variant="body-lg" color={color.text.primary.primary}>
              {intl.formatMessage(messages.redirectMessage)}
              <Typography
                as="a"
                variant="heading-sm"
                href={externalLink}
                color={color.text.text}
              >
                {isMobile
                  ? intl.formatMessage(messages.tapHere)
                  : intl.formatMessage(messages.clickLink)}
              </Typography>
            </Typography>
          </ContentWrapper>
          <AdWrapper>
            <DisplayAd
              slotConfigId="interstitialAd"
              targetingData={targetingData}
            />
          </AdWrapper>
        </AdWithTimer>
      </Container>
    </DefaultErrorBoundary>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${color.bg.fill.fill};
  padding: 0;
  margin: 0;
  ${mediumBreakpoint} {
    background-color: ${themeColor.greyMap};
  }
`;

const AdWithTimer = styled.div`
  display: flex;
  max-width: 928px;
  justify-content: center;
  align-items: center;
  gap: ${spacing.xxxxl};
  background-color: ${color.bg.fill.fill};
  border-radius: ${border_radius.rounded_lg};
  flex-direction: column;
  padding: ${spacing.xxxxl} ${spacing.xxl};
  ${mediumBreakpoint} {
    padding: ${spacing.xxxxl};
    flex-direction: row;
  }
`;

const ContentWrapper = styled.div`
  text-align: center;
  ${mediumBreakpoint} {
    padding: 0px ${spacing.xxl};
  }
`;

const Title = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing.xxl} 0px ${spacing.md} 0px;
`;

const AdWrapper = styled.div`
  width: 300px;
  height: 250px;
  background-color: ${color.bg.fill.fill};
  flex-shrink: 0;
`;
